import React from "react"

import Layout from "../components/layouts/layout/layout"
import InnerBanner from "../components/others/inner_banner"
import Privacy from "../components/others/privacy"

const PrivacyPolicyPage = () => (
  <Layout>
    <InnerBanner currentPageTitle={"Privacy Policy"} currentPageDescription={""} />
    <Privacy />
  </Layout>
)

export default PrivacyPolicyPage
